import usePusher from 'mp-communication/hooks/usePusher'
import { useCallback, useEffect } from 'react'
import useUserProfile from 'mp-profile/hooks/useUserProfile'

const listenedChannels = ['everyone-on-freight-board']
const listenedEvents = ['load_refresh']

type Props = {
  onRefresh: (data: any) => void,
}
const useFreightBoardCommunication = ({ onRefresh }: Props) => {
  const { user } = useUserProfile()
  const { connectEvent, disconnectEvent } = usePusher({ channels: listenedChannels })
  // const testIds = [4, 9]

  const onEventCallback = useCallback((data) => {
    // if (data.DoneBy !== user.id && !testIds.includes(data.DoneBy)) {
    //   onRefresh(data)
    // }
  }, [onRefresh, user])

  useEffect(() => {
    for (const listenedEvent of listenedEvents) {
      connectEvent(listenedEvent, onEventCallback)
    }

    return () => {
      for (const listenedEvent of listenedEvents) {
        disconnectEvent(listenedEvent)
      }
    }
  }, [onEventCallback])
}

export default useFreightBoardCommunication
