import useTranslation from 'next-translate/useTranslation'

const FreightInformationSection = ({ freight, additionalInfo }) => {
  const { t } = useTranslation('freight')

  return (
    <div className="tm-transactions-content active">
      <h2 className="mb-16">{t('freight_information')}</h2>
      <table className="tm-user-detail-tbl mt-16">
        <tbody>
          <tr>
            <td className="fw-600 text-left">{t('freight_type')}:</td>
            <td className="fw-400">{freight?.freightType?.value || '-'}</td>
          </tr>
          <tr>
            <td className="fw-600 text-left">{t('type_of_goods')}:</td>
            <td className="fw-400">{freight?.goodType?.value ? t(`options:${freight.goodType.value}`) : '-'}</td>
          </tr>
          <tr>
            <td colSpan={2}>
              <table className="tm-user-detail-tbl">
                <tbody>
                  <tr>
                    <td>
                      <div className="fw-600">{t('weight')}</div>
                      <div className="fw-400">{freight?.freightProps?.weight || '-'} <span className="text-color-black-40">{t('common:weight_suffix')}</span></div>
                    </td>
                    <td className="w-auto">
                      <div className="fw-600">{t('length')}</div>
                      <div className="fw-400">{freight?.freightProps?.length || freight?.freightProps?.height || '-'} <span className="text-color-black-40">{t('common:length_suffix')}</span></div>
                    </td>
                    <td>
                      <div className="fw-600">{t('volume')}</div>
                      <div className="fw-400">{freight?.freightProps?.volume || '-'} <span className="text-color-black-40">{t('common:volume_suffix')}</span></div>
                    </td>
                    <td>
                      <div className="fw-600">{t('loading_type')}</div>
                      <div className="fw-400">{freight?.freightProps?.loadingPosition || '-'}</div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
          <tr>
            <td className="fw-600 text-left">{t('additional_notes')}:</td>
            <td className="fw-400">{additionalInfo || '-'}</td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export default FreightInformationSection
