import { SummaryType } from 'mp-fex/components/table/TableSummary'
import { useState } from 'react'
import { freightInstance } from 'mp-structure/libs/freightApi'

const useFreightSummary = (isTenderBoard = false) => {
  const [summary, setSummary] = useState<SummaryType>({
    total: 0,
    new: 0
  })

  const fetchData = async () => {
    try {
      const { data } = await freightInstance.get(isTenderBoard ? 'tenders/summary' : 'shipments/summary')
      setSummary(data)
    } catch (e) {
      // not working
    }
  }

  return { summary, setSummary, fetchSummary: fetchData }
}

export default useFreightSummary
