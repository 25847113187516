import { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import useTranslation from 'next-translate/useTranslation'
import { GHref, GImage } from 'mp-common/components/form/elements'
import moment from 'moment/moment'
import { domainURL } from 'mp-structure/libs/api'
import { StateType } from 'mp-common/types/StateType'
import { get, isNumber } from 'lodash'
import { formatTime } from 'mp-structure/libs/utils'

const RouteInformationSection = ({ routes, mapsLink, withInstructions = false }) => {
  const { t } = useTranslation('quickview')
  const [detail, setDetail] = useState('')
  const countryFlagEndpoint = useSelector((state: StateType) => state.data.countryFlagEndpoint)
  const flagURL = useMemo(() => (domainURL + countryFlagEndpoint), [domainURL, countryFlagEndpoint])

  return (
    <div className="tm-transactions-content active">
      <h2 className="mb-16">
        <span>{t('route_information')}</span>
        <a href={mapsLink} target="_blank" rel="noreferrer">
          <GImage src="icon-km.svg" alt="km" width={20} height={20} />
        </a>
      </h2>
      <ul className="tm-multiple-stops">
        {routes?.map((route, index) => (
          <li key={route?.location?.id}>
            <div className="tm-multi-stop-no">{index + 1}</div>
            <div className="tm-multi-stop-content">
              <div className="tm-multi-stop-content-top">
                {flagURL && countryFlagEndpoint && get(route, 'location.countryCode') && (
                  <GImage
                    src={flagURL + get(route, 'location.countryCode') + '.png'}
                    className="round-image quick-view"
                    alt=""
                    width={32}
                    height={32}
                  />
                )}
                <div className="pr-16">
                  <div>{route?.location?.label}</div>
                  <div className="text-color-black-60">
                    {(route?.firstDate || route?.lastDate) && (
                      <>
                        {route?.firstDate ? moment(route?.firstDate)?.format('DD/MM') : ''}
                        {route?.lastDate ? ('-' + String(moment(route?.lastDate)?.format('DD/MM'))) : ''}
                        {' '}
                        {isNumber(route?.timeFirst) ? formatTime(route?.timeFirst) : ''}
                        {isNumber(route?.timeSecond) ? ('-' + formatTime(route?.timeSecond)) : ''}
                      </>
                    )}
                  </div>
                </div>
                <GHref
                  className="tm-multi-stop-open-details"
                  onClick={() => {
                    if (detail === route?.location?.id) {
                      setDetail('')
                    } else {
                      setDetail(route?.location?.id)
                    }
                  }}>
                  <GImage src="icon-acc-down.svg" alt="Expand" />
                </GHref>
              </div>
              <div className={`tm-multi-stop-content-bottom${detail === route?.location?.id ? ' opened' : ''}`}>
                <p className="fw-500">{t('address')}:</p>
                <p>
                  {route?.location?.address || '-'}
                </p>
                {withInstructions && (
                  <>
                    <p className="fw-500">{t('instructions')}:</p>
                    <p>
                      {route?.instructions || '-'}
                    </p>
                  </>
                )}
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default RouteInformationSection
