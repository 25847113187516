import useTranslation from 'next-translate/useTranslation'
import { GHref, GImage } from 'mp-common/components/form/elements'
import { copyToClipboard, dateToDMYhhMM, emailLink } from 'mp-structure/libs/utils'
import useMixPanel from 'mp-common/hooks/useMixPanel'
import useUserProfile from 'mp-profile/hooks/useUserProfile'

const ContactInformationSection = ({ contact, emailContent }) => {
  const { t } = useTranslation('quickview')
  const { user } = useUserProfile()
  const { trackEvent } = useMixPanel({ user })

  return (
    <div className="tm-transactions-content active mt-0">
      <h2 className="mb-16">{t('contact_information')}</h2>
      <div className="d-flex gap-8">
        <figure>
          <GImage
            src={contact?.avatarUrl}
            placeholder="placeholder-user-avatar.jpg"
            alt="Company Name"
            className="m-auto tm-user-avatar round-image"
            height={60}
            width={60}
          />
        </figure>
        <div className="d-flex column">
          <div className="text-20 fw-600 d-flex row gap-4">
            {contact?.name}
          </div>
          <div className="d-flex align-center gap-8">
            {t('email')}{': '}
            <a
              className="text-underline tm-link color-black-100"
              href={emailLink(emailContent.email, emailContent.subject, emailContent.body)}
              onClick={() => {
                trackEvent('EmailClicked', { email: contact?.email })
              }}
            >
              {contact?.email || '-'}
            </a>
            {contact?.email && (
              <GHref
                onClick={() => {
                  copyToClipboard(contact?.email)
                  trackEvent('EmailCopied', { email: contact?.email })
                }}
                className="tm-link opacity-50"
              >
                <GImage src="icon-copy.svg" alt="" width={16} height={16} />
              </GHref>
            )}
          </div>
          <div className="d-flex align-center gap-8">
            <span>{t('phone')}{': '}{contact?.phoneNumber || '-'}</span>
            <GHref
              onClick={() => copyToClipboard(contact?.phoneNumber)}
              className="tm-link opacity-50"
            >
              <GImage src="icon-copy.svg" alt="" width={16} height={16} />
            </GHref>
          </div>
          <div className="italic">
            {t('last_login')}{': '}
            {contact?.lastLoginDate ? dateToDMYhhMM(contact?.lastLoginDate) : '-'}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ContactInformationSection
