import useTranslation from 'next-translate/useTranslation'
import React from 'react'
import { dateToDMY } from 'mp-structure/libs/utils'
import { GImage } from 'mp-common/components/form/elements'
import dynamic from 'next/dynamic'

type Props = {
  activeFreight: any
}
const EllipsisToolTip = dynamic(() => import('ellipsis-tooltip-react-chan'), { ssr: false })

function SummaryHeaderSection(props: Props) {
  const { t } = useTranslation('quickview')
  const { activeFreight } = props

  return (
    <header>
      <div className="header-top w-100 text-left p-10">
        <div className="d-flex gap-8">
          <figure className="mr-10">
            <GImage
              src={activeFreight?.company?.logoThumbnail}
              placeholder="placeholder-company-avatar.jpg"
              alt="Company Name"
              className="m-auto round-image"
              width={60}
              height={60}
              style={{ mixBlendMode: 'darken' }}
            />
          </figure>
          <div className="d-flex column">
            <div className="text-20 fw-600 mr-24 company-name">
              <EllipsisToolTip>
                {activeFreight?.company?.name}
              </EllipsisToolTip>
            </div>
            <div className="text-14">VAT #: {activeFreight?.company?.vatId || '-'}</div>
            <div className="text-14">
              {t('subscription_date')}:
              {activeFreight?.company?.createdAt ? dateToDMY(activeFreight?.company?.createdAt) : '-'}
            </div>
            <div className="text-14">{activeFreight?.company?.address || '-'}</div>
          </div>
        </div>
      </div>
    </header>
  )
}

export default SummaryHeaderSection
