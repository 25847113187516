import { useState } from 'react'
import { FreightType } from 'mp-freight/types/FreightType'

const useQuickView = () => {
  const [showQuickView, setShowQuickView] = useState<boolean>(false)
  const [selectedListItem, setSelectedListItem] = useState(null)

  const addRemoveSelectedListItem = (lead: FreightType) => {
    const found = selectedListItem.destId === lead.destId
    if (!found) {
      setSelectedListItem(lead)
    } else {
      setSelectedListItem(null)
    }
  }

  const addSelectedListItem = (lead: FreightType) => {
    setSelectedListItem(lead)
  }

  const clearSelectedListItem = () => {
    setSelectedListItem(null)
  }

  const resetQuickView = () => {
    setSelectedListItem(null)
    setShowQuickView(false)
  }

  const selectAndShowQuickView = (freight: FreightType) => {
    setShowQuickView(false)
    addSelectedListItem(freight)
    setShowQuickView(true)
  }

  return {
    clearSelectedListItem,
    addSelectedListItem,
    addRemoveSelectedListItem,
    selectedListItem,
    showQuickView,
    setShowQuickView,
    selectAndShowQuickView,
    resetQuickView
  }
}

export default useQuickView
