import useTranslation from 'next-translate/useTranslation'

const TruckInformationSection = ({ truck, detailedTruckInformation }) => {
  const { t } = useTranslation('freight')

  return (
    <div className="tm-transactions-content active">
      <h2 className="mb-16">{t('truck_information')}</h2>
      <table className="tm-user-detail-tbl mt-16">
        <tbody>
          <tr>
            <td className="fw-600 text-left">{t('truck_type')}:</td>
            <td className="fw-400">{truck?.truckTypes?.length > 0 ? truck.truckTypes.map((t) => t.value).join(', ') : '-'}</td>
          </tr>
          {detailedTruckInformation && (
            <>
              <tr>
                <td className="fw-600 text-left">{t('freight_type')}:</td>
                <td className="fw-400">{truck?.freightType?.value || '-'}</td>
              </tr>
              <tr>
                <td className="fw-600 text-left">{t('max_weight')}:</td>
                <td className="fw-400">{truck?.maxWeight || '-'}</td>
              </tr>
              <tr>
                <td className="fw-600 text-left">{t('length')}:</td>
                <td className="fw-400">{truck?.length || '-'}</td>
              </tr>
            </>
          )}
          <tr>
            <td className="fw-600 text-left">{t('special_requirements')}:</td>
            <td className="fw-400 d-flex column gap-8">
              {
                !truck?.truckProps?.adr && !truck?.truckProps?.isAdr
                && truck?.truckProps.requiresTwoDrivers === 'no'
                && truck?.freightProps.value?.null
                && truck?.truckProps.others?.length === 0
                && truck?.truckProps.palletsExchange === 'no'
                && !truck?.truckProps.temperatureRange
                && '-'
              }
              {truck?.truckProps?.isAdr
                ? (
                  <div>
                    ADR
                    {truck?.truckProps?.adr && <span className="text-color-black-40">{' '}{t(`options:${truck?.truckProps?.adr}`)}</span>}
                    <br />
                  </div>
                )
                : ''}
              {truck?.truckProps?.requiresTwoDrivers !== 'no' ? <div>{t('table:two_drivers') }<br /></div> : ''}
              {truck?.truckProps?.palletsExchange !== 'no' ? <div>{t('table:pallets_exchange') }<br /></div> : ''}
              {truck?.truckProps?.temperatureRange
                ? (
                  <div>
                    {t('table:temperature_control') }
                    {(truck?.truckProps?.temperatureRange?.min || truck?.truckProps?.temperatureRange?.max)
                      && (
                        <span className="text-color-black-40">
                          {truck?.truckProps?.temperatureRange?.min ? ` ${truck?.truckProps?.temperatureRange?.min}°C` : ''}
                          {truck?.truckProps?.temperatureRange?.max ? ` - ${truck?.truckProps?.temperatureRange?.max}°C` : ''}
                        </span>
                      )}
                    <br />
                  </div>
                )
                : ''}
              {!truck?.freightProps?.value?.null ? (
                <div>
                  {t('options:high_value_load')}
                  <span className="text-color-black-40">{' '}{truck?.freightProps?.value?.value}</span>
                </div>
              ) : ''}
              {truck?.truckProps?.others?.length > 0 && !!truck?.truckProps?.others[0] ? (
                <div>
                  {t('onboarding:other')}
                  <span className="text-color-black-40">{' '}{truck?.truckProps?.others}</span>
                </div>
              ) : ''}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export default TruckInformationSection
