import { useCallback, useEffect, useState } from 'react'
import { freightApiGet } from 'mp-structure/libs/freightApi'
import useTenderBoardCommunication from 'mp-communication/hooks/useTenderBoardCommunication'
import useFreightBoardCommunication from 'mp-communication/hooks/useFreightBoardCommunication'
import useMarkAsRead from 'mp-truck/hooks/useMarkAsRead'
import useTranslation from 'next-translate/useTranslation'

type Props = {
  selectedListItem: any,
  showQuickView: boolean
  isTenderView?: boolean
}

const useQuickView = (props: Props) => {
  const { t } = useTranslation()
  const { selectedListItem, showQuickView, isTenderView } = props
  const [activeTab, setActiveTab] = useState(1)
  const [contentPadding, setContentPadding] = useState(0)
  const [showNegotiationMessage, setShowNegotiationMessage] = useState(false)
  const [loading, setLoading] = useState(false)
  const [documentUrl, setDocumentUrl] = useState('')
  const [activeFreight, setActiveFreight] = useState(null)
  const { markTenderAsRead, markShipmentAsRead } = useMarkAsRead()

  const getDetailsHeight = () => {
    let height = 260
    if (document && document.getElementById('dvDetailsBottom')) {
      height = (document.getElementById('dvDetailsBottom') as HTMLInputElement).clientHeight
    }
    return height
  }

  const getTenderDocument = async (documentId) => {
    const res = await freightApiGet(`/document/${documentId}/link`)
    if (res?.includes('https')) {
      setDocumentUrl(res)
    }
  }

  const getFreight = async () => {
    setLoading(true)
    const id = selectedListItem?.id
    const res = await freightApiGet(isTenderView ? `/tender/${id}?detailed=true` : `/shipment/${id}?detailed=true`)
    if (res?.id) {
      if (isTenderView && res?.docUrls?.length > 0) {
        await getTenderDocument(res.docUrls[0])
      }
      setActiveFreight({
        ...res,
        ...res?.freightProps?.loadingPosition && ({ freightProps: {
          ...res?.freightProps,
          loadingPosition: res?.freightProps?.loadingPosition?.split(',')?.map((p) => t(`options:${p}`))?.join(',')
        }
        })
      })
    }
    setLoading(false)
  }

  const onRefresh = useCallback((data: any) => {
    if (isTenderView && selectedListItem && selectedListItem.id === data?.TenderId) {
      getFreight()
    }

    if (!isTenderView && selectedListItem && selectedListItem.id === data?.FreightId) {
      getFreight()
    }
  }, [selectedListItem, isTenderView])

  useTenderBoardCommunication({ onRefresh })
  useFreightBoardCommunication({ onRefresh })

  useEffect(() => {
    setContentPadding(getDetailsHeight() + 50)
  }, [showNegotiationMessage, activeTab])

  // when selected leads are changed, reset index to get first lead.
  useEffect(() => {
    if (!selectedListItem) {
      setActiveFreight(null)
    }
  }, [selectedListItem])

  useEffect(() => {
    if (showQuickView) {
      if (selectedListItem?.id) {
        getFreight()
        if (selectedListItem?.isNew) {
          if (isTenderView) {
            markTenderAsRead(selectedListItem?.id)
          } else {
            markShipmentAsRead(selectedListItem?.id)
          }
        }
      }
    } else {
      setActiveFreight(null)
      setDocumentUrl('')
    }
  }, [selectedListItem?.id, showQuickView])

  return {
    activeTab,
    setActiveTab,
    contentPadding,
    setContentPadding,
    showNegotiationMessage,
    setShowNegotiationMessage,
    activeFreight,
    setActiveFreight,
    documentUrl
  }
}

export default useQuickView
