import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import useTranslation from 'next-translate/useTranslation'
import useBoardFilterItems from 'mp-fex/hooks/filters/useBoardFilterItems'
import { GHref, GImage } from 'mp-common/components/form/elements'
import { AnimatePresence, motion } from 'framer-motion'
import { TenderBoardRequestType } from 'mp-tender/types/TenderBoardTypes'
import { dispatchSaveFilterModal } from 'mp-common/stores/modal/action'
import { useDispatch } from 'react-redux'
import { defaultFreightBoardFilters } from 'mp-freight/hooks/useFreightBoardFilters'

const animationVariants = {
  open: { opacity: 1, x: 0 },
  closed: { opacity: 0, x: '100%' }
}

interface Props {
  showFilters?: boolean,
  setShowFilters?: Dispatch<SetStateAction<boolean>>,
  withFreightTargetPriceFilter?: boolean,
  withPublicationDateFilter?: boolean,
  withFreightTypeFilter?: boolean,
  withTruckTypeFilter?: boolean,
  withSpecialRequestsFilter?: boolean,
  withTenderInfoFilters?: boolean,
  isFreightBoard?: boolean
  isTenderBoard?: boolean
  queryForm: TenderBoardRequestType
  applyFilter: (_filters?: Record<string, any>, isResetAction?: boolean) => void,
  setSavedFilters?: (filters:any) => void
}

function Filters(props: Props): JSX.Element {
  const {
    showFilters,
    setShowFilters,
    withFreightTargetPriceFilter,
    withPublicationDateFilter,
    withFreightTypeFilter,
    withTruckTypeFilter,
    withSpecialRequestsFilter,
    withTenderInfoFilters,
    isFreightBoard,
    isTenderBoard,
    queryForm,
    setSavedFilters,
    applyFilter
  } = props
  const { t } = useTranslation()
  const ref = useRef<any>(null)
  const dispatch = useDispatch()
  const [isListenerMounted, setIsListenerMounted] = useState(false)
  const [localForm, setLocalForm] = useState(queryForm)

  const disableSaveFilter = useMemo(() => {
    if (isTenderBoard) {
      return true
    }
    const { loadingDate, unloadingDate, publicationDate, ...rest } = localForm
    return JSON.stringify(rest) === JSON.stringify(defaultFreightBoardFilters) || (JSON.stringify(queryForm) === JSON.stringify(rest))
  }, [localForm, defaultFreightBoardFilters, isTenderBoard, queryForm])

  const onChange = (k, v) => setLocalForm(prev => ({ ...prev, [k]: v }))

  const {
    ownerInfoFilters,
    loadingFilters,
    unloadingFilters,
    freightTargetPriceFilter,
    publicationDateFilter,
    freightTypeFilter,
    truckTypeFilter,
    specialRequestsFilter,
    tenderInfoFilters
  } = useBoardFilterItems({ form: localForm, onChange, isFreightBoard, isTenderBoard })

  const escFunction = (e) => {
    if (e.key === 'Escape' && setShowFilters) {
      setShowFilters(false)
    }
  }

  const handleEnterKey = useCallback((event) => {
    if (event.key === 'Enter' && setShowFilters) {
      applyFilter(localForm)
      setShowFilters(false)
    }
  }, [localForm])

  const saveFilter = (filter) => {
    setSavedFilters(filter)
    setShowFilters(false)
  }

  useEffect(() => {
    if (!isListenerMounted && showFilters) {
      setIsListenerMounted(true)
      document.removeEventListener('keydown', escFunction)
      document.addEventListener('keydown', escFunction, false)
    }
    return () => {
      document.removeEventListener('keydown', escFunction)
      setIsListenerMounted(false)
    }
  }, [showFilters])

  useEffect(() => {
    const div = ref.current
    if (div) {
      div?.addEventListener('keyup', handleEnterKey)
    }
    return () => {
      div?.removeEventListener('keyup', handleEnterKey)
    }
  }, [localForm])

  useEffect(() => {
    setLocalForm(queryForm)
  }, [queryForm])

  return (
    <AnimatePresence>
      <motion.aside
        key="filters-fex-sidebar"
        initial={{ opacity: 0, x: '100%' }}
        animate={showFilters ? 'open' : 'closed'}
        variants={animationVariants}
        transition={{ duration: 0.1 }}
        className="tm-right-sidebar tm-transactions-filters-sidebar opened"
      >
        <>
          <GHref
            className="tm-close-sidebar"
            onClick={() => setShowFilters(show => !show)}
          />
          <header>
            <GImage src="/icon-filter.svg" alt={t('table:filter_your_results')} />
            <span>{t('table:filter_your_results')}</span>
          </header>
          <div className="tm-right-sidebar-content tm-custom-scroll" ref={ref}>
            <form>
              {ownerInfoFilters}
              {loadingFilters}
              {unloadingFilters}
              {withTenderInfoFilters && tenderInfoFilters}
              {withFreightTargetPriceFilter && freightTargetPriceFilter}
              {withPublicationDateFilter && publicationDateFilter}
              {withFreightTypeFilter && freightTypeFilter}
              {withTruckTypeFilter && truckTypeFilter}
              {withSpecialRequestsFilter && specialRequestsFilter}
            </form>
          </div>
          <div className="tm-transactions-bottom-buttons">
            <GHref
              className="confirm"
              onClick={() => {
                applyFilter(localForm)
                setShowFilters(false)
              }}
            >
              {t('table:filter')}
            </GHref>
            {!isTenderBoard && (
              <GHref
                className="confirm"
                onClick={() => {
                  dispatchSaveFilterModal({ localForm, saveFilter }, dispatch)
                }}
                disabled={disableSaveFilter}
              >
                {t('table:save_filter')}
              </GHref>
            )}
          </div>
        </>
      </motion.aside>
    </AnimatePresence>
  )
}

export default Filters
