import { useMemo } from 'react'
import InputLabeledContainer from 'mp-common/components/form/containers/InputLabeledContainer'
import TextInput from 'mp-common/components/form/inputs/TextInput'
import useTranslation from 'next-translate/useTranslation'
import RangeInput from 'mp-common/components/form/inputs/RangeInput'
import { CheckBoxMultiple, CurrencyInput, RadioGroup, RangeDatePicker, SingleDatePicker } from 'mp-common/components/form/inputs'
import SelectInput from 'mp-common/components/form/inputs/SelectInput'
import LocationInput from 'mp-common/components/form/inputs/LocationInput'
import useOptions from 'mp-common/hooks/useOptions'

const useBoardFilterItems = ({ form, onChange, isFreightBoard = false, isTenderBoard = false }) => {
  const { t } = useTranslation()
  const { translatedTruckTypes, freightTypes, specialRequirementOptionsLong } = useOptions()

  const ownerInfoFilters = useMemo(() => (
    <>
      <InputLabeledContainer
        className="mb-16"
        form={form}
        name="companyName"
        label={t('table:company_name')}
        labelClassName="form-label mb-4"
        onChange={onChange}
        renderInput={(props) => (
          <div className="pos-rel">
            <TextInput {...props} />
          </div>
        )}
      />
      <InputLabeledContainer
        className="mb-16"
        form={form}
        name="contactName"
        label={t('table:contact_person')}
        labelClassName="form-label mb-4"
        onChange={onChange}
        renderInput={(props) => (
          <div className="pos-rel">
            <TextInput {...props} />
          </div>
        )}
      />
      <div className="hr"></div>
    </>
  ), [form, onChange])

  const loadingFilters = useMemo(() => (
    <>
      {isFreightBoard && (
        <InputLabeledContainer
          className="mb-16"
          form={form}
          name="locationsFrom"
          label={t('table:loading_location')}
          labelClassName="form-label mb-4"
          onChange={onChange}
          renderInput={(props) => (
            <LocationInput {...props} isMulti value={form?.locationsFrom} />
          )}
        />
      )}
      {isTenderBoard && (
        <InputLabeledContainer
          className="mb-16"
          form={form}
          name="locationFrom"
          label={t('table:loading_location')}
          labelClassName="form-label mb-4"
          onChange={onChange}
          renderInput={(props) => (
            <LocationInput {...props} value={form?.locationFrom} />
          )}
        />
      )}
      <InputLabeledContainer
        className="mb-16"
        form={form}
        name="radiusFrom"
        label={t('table:radius_range')}
        labelClassName="form-label mb-4"
        onChange={onChange}
        renderInput={(props) => (
          <RangeInput
            min={0}
            defaultValue={200}
            {...props}
          />
        )}
      />
      {!isTenderBoard && (
        <InputLabeledContainer
          className="mb-16"
          form={form}
          name={isFreightBoard ? 'loadingDate' : 'truckDate'}
          label={isFreightBoard ? t('table:loading_dates') : (t('table:truck_availability') + ' (' + t('table:from_to') + ')')}
          labelClassName="form-label mb-4"
          onChange={onChange}
          renderInput={(props) => (
            <RangeDatePicker
              eClass="w-100"
              {...props}
            />
          )}
        />
      )}
      <div className="hr"></div>
    </>
  ), [form, onChange, isFreightBoard, isTenderBoard])

  const unloadingFilters = useMemo(() => (
    <>
      {isFreightBoard && (
        <InputLabeledContainer
          className="mb-16"
          form={form}
          name="locationsTo"
          label={t('table:unloading_location')}
          labelClassName="form-label mb-4"
          onChange={onChange}
          renderInput={(props) => (
            <LocationInput {...props} isMulti value={form?.locationsTo} />
          )}
        />
      )}
      {isTenderBoard && (
        <InputLabeledContainer
          className="mb-16"
          form={form}
          name="locationTo"
          label={t('table:unloading_location')}
          labelClassName="form-label mb-4"
          onChange={onChange}
          renderInput={(props) => (
            <div className="pos-rel">
              <LocationInput {...props} value={form?.locationTo} />
            </div>
          )}
        />
      )}
      <InputLabeledContainer
        className="mb-16"
        form={form}
        name="radiusTo"
        label={t('table:radius_range')}
        labelClassName="form-label mb-4"
        onChange={onChange}
        renderInput={(props) => (
          <RangeInput
            min={0}
            defaultValue={200}
            {...props}
          />
        )}
      />
      {!isTenderBoard && (
        <InputLabeledContainer
          className="mb-16"
          form={form}
          name="unloadingDate"
          label={t('table:unloading_dates')}
          labelClassName="form-label mb-4"
          onChange={onChange}
          renderInput={(props) => (
            <RangeDatePicker
              eClass="w-100"
              {...props}
            />
          )}
        />
      )}
      <div className="hr"></div>
    </>
  ), [form, onChange, isFreightBoard, isTenderBoard])

  const truckTypeFilter = useMemo(() => (
    <InputLabeledContainer
      className="mb-16"
      form={form}
      name="truckType"
      label={t('table:truck_type')}
      labelClassName="form-label mb-4"
      onChange={onChange}
      renderInput={(props) => (
        <SelectInput
          className="form-select w-100"
          items={translatedTruckTypes}
          {...props}
        />
      )}
    />
  ), [form, onChange, translatedTruckTypes])

  const freightTypeFilter = useMemo(() => (
    <InputLabeledContainer
      className="mb-16"
      form={form}
      name="freightType"
      label={t('table:freight_type')}
      labelClassName="form-label mb-4"
      onChange={onChange}
      renderInput={(props) => (
        <RadioGroup
          items={freightTypes}
          {...props}
        />
      )}
    />
  ), [form, onChange, freightTypes])

  const publicationDateFilter = useMemo(() => (
    <InputLabeledContainer
      className="mb-16"
      form={form}
      name="publicationDate"
      label={t('table:publication_date')}
      labelClassName="form-label mb-4"
      onChange={onChange}
      renderInput={(props) => (
        <RangeDatePicker
          eClass="w-100"
          {...props}
        />
      )}
    />
  ), [form, onChange])

  const freightTargetPriceFilter = useMemo(() => (
    <InputLabeledContainer
      className="mb-16"
      form={form}
      name="freightTargetPrice"
      label={t('table:price')}
      labelClassName="form-label mb-4"
      onChange={onChange}
      renderInput={(props) => (
        <CurrencyInput
          className="form-input w-100"
          placeholder="€0.00"
          {...props}
        />
      )}
    />
  ), [form, onChange])

  const specialRequestsFilter = useMemo(() => (
    <InputLabeledContainer
      className="mb-16"
      form={form}
      name="specialRequirements"
      label={t('table:special_requirements')}
      labelClassName="form-label mb-4"
      onChange={onChange}
      renderInput={(props) => (
        <CheckBoxMultiple
          items={specialRequirementOptionsLong}
          flow="column"
          {...props}
        />
      )}
    />
  ), [form, onChange])

  const tenderInfoFilters = useMemo(() => (
    <>
      <InputLabeledContainer
        name="shipmentPeriod"
        className="mb-16"
        label={t('freight:period_of_shipments')}
        form={form}
        onChange={onChange}
        renderInput={(props) => (
          <RangeDatePicker {...props} />
        )}
      />
      {/* <div className="w-100 mp-grid-2-col gap-8">
        <InputLabeledContainer
          className="mb-16"
          form={form}
          name="freightCount"
          label={t('table:freights')}
          labelClassName="form-label mb-4"
          onChange={onChange}
          renderInput={(props) => (
            <NumberInput {...props} />
          )}
        />
        <InputLabeledContainer
          name="periodFreightInterval"
          className="mb-16"
          label={t('table:per')}
          form={form}
          labelClassName="form-label mb-4"
          onChange={onChange}
          renderInput={(props) => (
            <SelectInput
              items={timeIntervalItems}
              {...props}
            />
          )}
        />
      </div>
      <InputLabeledContainer
        className="mb-16"
        form={form}
        name="offerDeadline"
        label={t('table:deadline_to_receiver_offers')}
        labelClassName="form-label mb-4"
        onChange={(k, v) => onChange(k, [v])}
        renderInput={(props) => (
          <SingleDatePicker
            {...props}
          />
        )}
      />
      {freightTargetPriceFilter}
       */}
      <div className="hr"></div>
    </>
  ), [form, onChange])

  return {
    ownerInfoFilters,
    loadingFilters,
    unloadingFilters,
    freightTargetPriceFilter,
    publicationDateFilter,
    freightTypeFilter,
    truckTypeFilter,
    specialRequestsFilter,
    tenderInfoFilters
  }
}

export default useBoardFilterItems
