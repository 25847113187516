import useTranslation from 'next-translate/useTranslation'
import { dateToDMY } from 'mp-structure/libs/utils'

type Props = {
  activeFreight: any
}

function InformationSection(props:Props) {
  const { t } = useTranslation('quickview')
  const { activeFreight } = props

  return (
    <div className="tm-transactions-content active">
      <div className="tm-transactions-details-col-2">
        <div className="left">
          <div className="info-box pb-16">
            <div className="info-box-top fw-600">{t('price')}</div>
            <div className="text-24 fw-600">{activeFreight?.price || '-'}</div>
            {activeFreight?.paymentTerms && (
              <div>{t('payment_terms')}: {activeFreight?.paymentTerms}{' '}{t('common:days')}</div>
            )}
          </div>
        </div>
        <div className="right">
          <div className="info-box flex-1">
            <div className="info-box-top fw-600">{t('publish_date')}</div>
            <div className="d-flex column justify-center flex-1">{dateToDMY(activeFreight?.publicationDate)}</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default InformationSection
