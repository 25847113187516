import { useMemo } from 'react'
import GImage from 'mp-common/components/form/elements/GImage'
import useTranslation from 'next-translate/useTranslation'
import { GHref } from 'mp-common/components/form/elements'
import { useRouter } from 'next/router'
import useSession from 'mp-auth/hooks/useSession'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'
import { defaultTenderBoardFilter } from 'mp-tender/hooks/useTenderBoard'
import Trans from 'next-translate/Trans'

const exceptionalKeys = [
  'page',
  'perPage',
  'orderBy',
  'radiusFrom',
  'radiusTo',
  'sort',
  'sortOrder'
]

export type SummaryType = {
  total?: number,
  new?: number,
  message?: string
}

type TableSummaryProps = {
  summary: SummaryType,
  link: string,
  title: string,
  summaryKey: string,
  setShowFilters?:any,
  resetFilters?:any
  suggestions?: any
}

function TableSummary(props: TableSummaryProps): JSX.Element {
  const {
    summary,
    link,
    title,
    summaryKey,
    setShowFilters,
    resetFilters
  } = props
  const { total: itemCount, new: newItemCount, message } = summary
  const { t } = useTranslation()
  const { isCarrier } = useSession()
  const { query } = useRouter()

  const activeFiltersCount = useMemo(() => {
    let count = 0
    if (!isNil(query) && !isEmpty(query) && Object.keys(query)?.length > 0) {
      count = Object.keys(query)
        .filter(key => query[key].toString() !== defaultTenderBoardFilter[key]?.toString())
        .filter(key => !exceptionalKeys.includes(key)).length
      return count
    }
    return count
  }, [query])

  return (
    <>
      <div className="d-flex align-center justify-space">
        <div className="tm-transaction-extra-info">
          {!!itemCount && !message && (
            <>
              <span className="mr-4">{t('table:you_have')}</span>
              <Trans
                i18nKey={summaryKey + (itemCount !== 1 ? 's' : '')}
                values={{ count: itemCount }}
                components={{ b: <b className="ml-4" /> }}
              />
              {/* <strong className="ml-4">{t(summaryKey + (itemCount !== 1 ? 's' : ''), { count: itemCount })}</strong> */}
              {newItemCount > 0 && <strong className="new">{t('table:x_new_entries', { count: newItemCount })}</strong>}
            </>
          )}
          {message && (
            <>
              <div className="d-flex row align-center">
                <Trans i18nKey={`table:${message}`} components={{ a: <strong className="new" /> }} />
              </div>
              {/* <strong className="new">{t('table:suggestions_text')}</strong> */}
            </>
          )}
        </div>
        <ul className="tm-content-tabs">
          {(itemCount > 0 || activeFiltersCount > 0) && (
            <>
              <li>
                <GHref title={t('table:filters')} className="btn-filters" onClick={() => setShowFilters(true)}>
                  {t('table:filters')}
                  <GImage src="icon-filter.svg" alt="Filters" width={16} height={16} />
                  {activeFiltersCount > 0 && <span className="notif-count">{activeFiltersCount}</span>}
                </GHref>
              </li>
              <li>
                <GHref
                  className="btn-filters"
                  tip={t('table:reset_filters')}
                  style={{ width: 40, marginLeft: '-8px' }}
                  onClick={() => {
                    resetFilters()
                    setShowFilters(false)
                  }}
                >
                  <GImage src="icon-reset.svg" alt="Reset Filters" width={16} height={16} />
                </GHref>
              </li>
            </>
          )}
          {!isCarrier && (
            <li>
              <GHref href={link} title={title} className="btn-add-new">
                {title}
              </GHref>
            </li>
          )}
        </ul>
      </div>
    </>
  )
}

export default TableSummary
