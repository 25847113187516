import { useCallback, useEffect, useState } from 'react'
import { TenderType } from 'mp-tender/types/TenderType'
import { TenderBoardRequestType } from 'mp-tender/types/TenderBoardTypes'
import { freightApiPost } from 'mp-structure/libs/freightApi'
import { useRouter } from 'next/router'
import { defaultPagination } from 'mp-structure/libs/constants'
import useTenderBoardCommunication from 'mp-communication/hooks/useTenderBoardCommunication'
import useFreightBoardFilters from 'mp-freight/hooks/useFreightBoardFilters'

export const defaultTenderBoardFilter = {
  page: 1,
  perPage: 30,
  radiusFrom: 200,
  radiusTo: 200
}

const useTenderBoard = ({ setSummary, fetchSummary }) => {
  const { query } = useRouter()
  const [listLoading, setListLoading] = useState<boolean>(false)
  const [tenderList, setTenderList] = useState<TenderType[]>([])
  const [pagination, setPagination] = useState(defaultPagination)
  const [isRemoveConfirmed, setIsRemoveConfirmed] = useState(false)
  const [showRowMenu, setShowRowMenu] = useState<string>('')
  const { form: localForm } = useFreightBoardFilters()

  const fetchTenderList = async (_filters?: TenderBoardRequestType, scrollCall = false) => {
    setListLoading(true)
    try {
      const params = _filters ? { ..._filters } : { ...defaultTenderBoardFilter }
      const _params = { ...params } as Record<string, any>
      if (_params?.locationFrom) {
        _params.locationFrom = {
          lat: _params.locationFrom.latitude,
          lng: _params.locationFrom.longitude,
          label: _params.locationFrom?.label,
          ...(_params?.locationFrom?.cityName && { cityName: _params?.locationFrom?.cityName }),
          ...(_params?.locationFrom?.countryCode && { countryCode: _params?.locationFrom?.countryCode })
        }
      }
      if (_params?.locationTo) {
        _params.locationTo = {
          lat: _params.locationTo.latitude,
          lng: _params.locationTo.longitude,
          label: _params.locationTo?.label,
          ...(_params?.locationTo?.cityName && { cityName: _params?.locationTo?.cityName }),
          ...(_params?.locationTo?.countryCode && { countryCode: _params?.locationTo?.countryCode })
        }
      }
      if (_params?.freightTargetPrice && typeof _params.freightTargetPrice === 'string') {
        _params.freightTargetPrice = parseFloat(_params.freightTargetPrice)
      }

      if (_params?.specialRequirements && Array.isArray(_params.specialRequirements)) {
        _params.specialRequirements.forEach(item => {
          _params[item] = true
        })
      }

      const { data } = await freightApiPost('/tenders', _params)
      if (data?.data) {
        setTenderList(scrollCall ? [...tenderList, ...data.data] : [...data.data])
      }
      if (data?.pagination) {
        setPagination(data?.pagination)
      }
      if (JSON.stringify(params) === JSON.stringify(defaultTenderBoardFilter)) {
        fetchSummary()
      } else {
        setSummary({
          total: data?.pagination?.total,
          new: 0
        })
      }
      setListLoading(false)
    } catch {
      setTenderList([])
      setListLoading(false)
    }
  }

  const onRefresh = useCallback(() => {
    if (localForm === null || localForm.page === 1) {
      fetchTenderList(localForm)
    }
  }, [localForm])

  useTenderBoardCommunication({ onRefresh })

  useEffect(() => setIsRemoveConfirmed(false), [setShowRowMenu])

  useEffect(() => () => (setIsRemoveConfirmed(false)), [])

  useEffect(() => {
    fetchTenderList(localForm)
  }, [localForm])

  return {
    tenderList,
    setTenderList,
    listLoading,
    fetchTenderList,
    localForm,
    pagination,
    showRowMenu,
    setShowRowMenu,
    isRemoveConfirmed,
    setIsRemoveConfirmed,
    query
  }
}

export default useTenderBoard
