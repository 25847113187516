import usePusher from 'mp-communication/hooks/usePusher'
import { useCallback, useEffect } from 'react'
import useUserProfile from 'mp-profile/hooks/useUserProfile'

const listenedChannels = ['everyone-on-tender-board']
const listenedEvents = ['tender_refresh']

type Props = {
  onRefresh: (data: any) => void,
}

const useTenderBoardCommunication = ({ onRefresh }: Props) => {
  const { user } = useUserProfile()
  const { connectEvent, disconnectEvent } = usePusher({ channels: listenedChannels })

  const onEventCallback = useCallback((data) => {
    console.log('received data on event callback', data, user)
    if (data.DoneBy !== user.id) {
      onRefresh(data)
    }
  }, [onRefresh, user])
  useEffect(() => {
    for (const listenedEvent of listenedEvents) {
      connectEvent(listenedEvent, onEventCallback)
    }

    return () => {
      for (const listenedEvent of listenedEvents) {
        disconnectEvent(listenedEvent)
      }
    }
  }, [onEventCallback])
}

export default useTenderBoardCommunication
